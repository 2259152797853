export const menuItems = [
  
 
  {
    name: "Comparatifs",
    label: "Comparatifs",
    items: [
      { name: "banque-en-ligne/", label: "Banques en ligne" },
      { name: "banque-pro/", label: "Banques pro" },
      { name: "carte-bancaire/", label: "Cartes bancaires" },
      { name: "compte-courant/", label: "Comptes courants" },
      { name: "neobanque/", label: "Néobanques" },

    ],
  },
  {
    name: "Avis Banques",
    label: "Avis Banques",
    items: [
      { name: "banque/", label: "Tous les avis" },
      { name: "banque/avis-bforbank/", label: "BforBank" },
      { name: "banque/avis-boursorama-banque/", label: "Boursorama Banque" },
      { name: "banque/avis-fortuneo-banque/", label: "Fortuneo Banque" },
      { name: "banque/avis-hello-bank/", label: "Hello bank" },
      { name: "banque/avis-ing/", label: "ING" },
      { name: "banque/avis-monabanq/", label: "Monabanq" },
      { name: "banque/avis-orange-bank/", label: "Orange Bank" },
      { name: "banque/avis-societe-generale/", label: "Société Générale" },
    ],
  },
  
  {
    name: "Banque Académie",
    label: "Banque Académie",
    items: [
      {
        name: "Changer de banque",
        label: "Changer de banque",
        items: [
          { name: "academie/changer-de-banque-comment-faire/", label: "Changer de banque" },
          { name: "academie/ouvrir-un-compte-bancaire-en-ligne/", label: "Ouvrir un compte" },
          { name: "academie/cloturer-son-compte-bancaire-comment-faire/", label: "Clôturer un compte" },
          { name: "academie/erreurs-a-eviter-en-banque-en-ligne/", label: "Erreurs à éviter" },
  
         
        ],
      },
 
      
            {
              name: "Comment choisir?",
              label: "Comment choisir?",
              items: [
                { name: "academie/classement-des-banques-francaises-quels-criteres/", label: "Classement des banques" },
                { name: "academie/banques-en-ligne-le-comparatif/", label: "Comparatif banques" },
                { name: "academie/comparateur-de-banque-en-ligne-trouver-la-moins-chere/", label: "Comparateur banques" },
                { name: "academie/choisir-sa-banque-quelle-est-la-moins-chere/", label: "La moins chère" },
                { name: "academie/quelle-banque-en-ligne-choisir/", label: "Laquelle choisir?" },
                { name: "academie/meilleure-banque-qui-choisir/", label: "Meilleure banque" },
                { name: "academie/les-meilleures-banques-en-ligne/", label: "Meilleure banque en ligne" },
              ],
            },
      {
        name: "Frais bancaires",
        label: "Frais bancaires",
        items: [
          { name: "academie/cotisation-carte-bancaire-quelle-est-la-banque-la-moins-chere/", label: "Carte bancaire" },
          { name: "academie/frais-de-decouvert-des-banques-quelle-est-la-moins-chere/", label: "Frais de découvert" },
          { name: "academie/frais-de-tenue-de-compte-bancaire-peut-on-les-eviter/", label: "Frais de tenue de compte" },
          { name: "academie/les-banques-et-les-paiements-hors-zone-euro-quels-couts/", label: "Paiements hors zone euro" },
          { name: "academie/tarifs-des-banques-les-principaux-frais-bancaires/", label: "Principaux frais" },
        ],
      },


      {
              name: "Guides pratique",
              label: "Guides pratique",
              items: [
                { name: "academie/les-neo-banques-cest-quoi/", label: "Néobanques" },
                { name: "academie/banque-a-distance/", label: "Banques à distance" },
                { name: "academie/banques-traditionnelles-quelle-est-la-moins-chere/", label: "Banques traditionnelles" },
                { name: "academie/banques-en-ligne-laquelle-choisir/", label: "Banque en ligne" },
              
              ],
            },
           
    ],
      },
      // { name: "find-an-investor/", label: `Find an Investor 🔥` },
         
        
  
     
  
      




     
  

 
  // {
  //   name: "pages",
  //   label: "Pages",
  //   items: [
  //     { name: "about", label: "About" },
  //     {
  //       name: "career",
  //       label: "Career",
  //       items: [
  //         { name: "career", label: "Career List" },
  //         { name: "career-details", label: "Career Details" },
  //       ],
  //     },
  //     {
  //       name: "case-study",
  //       label: "Case Study",
  //       items: [
  //         { name: "case-study", label: "Case Study" },
  //         { name: "case-study-details", label: "Case Study Details" },
  //       ],
  //     },
  //     {
  //       name: "blog",
  //       label: "Blog",
  //       items: [
  //         { name: "blog-regular", label: "Blog Regular" },
  //         { name: "blog-left-sidebar", label: "Blog Left Sidebar" },
  //         { name: "blog-right-sidebar", label: "Blog Right Sidebar" },
  //         { name: "blog-details", label: "Blog Details" },
  //       ],
  //     },
  //     {
  //       name: "contact",
  //       label: "Contact",
  //       items: [
  //         { name: "contact-1", label: "Contact 01" },
  //         { name: "contact-2", label: "Contact 02" },
  //         { name: "contact-3", label: "Contact 03" },
  //       ],
  //     },
  //     {
  //       name: "account",
  //       label: "Account",
  //       items: [
  //         { name: "signin", label: "Signin" },
  //         { name: "signin-bg", label: "Signin BG" },
  //         { name: "signup", label: "Signup" },
  //         { name: "signup-bg", label: "Signup BG" },
  //         { name: "reset-pass", label: "Reset Password" },
  //         { name: "reset-pass-bg", label: "Reset Password BG" },
  //       ],
  //     },
  //     {
  //       name: "pricing",
  //       label: "Pricing",
  //       items: [
  //         { name: "pricing-1", label: "Pricing 01" },
  //         { name: "pricing-2", label: "Pricing 02" },
  //       ],
  //     },
  //     {
  //       name: "utility",
  //       label: "Utility",
  //       items: [
  //         { name: "coming-soon", label: "Coming Soon" },
  //         { name: "coming-soon-2", label: "Coming Soon 02" },
  //         { name: "404", label: "404 Page" },
  //         { name: "thank-you", label: "Thank You" },
  //         { name: "terms-conditions", label: "Terms & Conditions" },
  //       ],
  //     },
  //     {
  //       name: "essential",
  //       label: "Essential",
  //       items: [
  //         { name: "faq", label: "FAQ" },
  //         { name: "reviews", label: "Reviews" },
  //       ],
  //     },
  //   ],
  // },
  
];
