import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Box } from "../Core";
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight} from '@fortawesome/free-solid-svg-icons'

const TitleStyled = styled(Title)`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 22px;
`;

const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    line-height: 1.2;
    a {
      color: white !important;
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => '#5454d4'} !important;
      }
    }
  }
`;

const CopyRightArea = styled.div`
  border-top: ${({ dark, theme }) =>
    dark ? `1px solid #2f2f31 ` : `1px solid #413e65`};

  padding: 15px 0;
  p {
    color: ${({ dark, theme }) =>
      dark ? '#ffffff' : '#19191b'};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: ${({ dark, theme }) =>
        dark ? '#ffffff' : '#19191b'} !important;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => '#5454d4'} !important;
      }
    }
  }
`;

const Footer = ({ isDark = true }) => {
  return (
    <>
      {/* <!-- Footer section --> */}
      <Box bg={isDark ? "dark" : "light"}>
        <Container>
          <Box
            css={`
              padding: 80px 0 60px;
            `}
          >
                        <Row><p style={{maxWidth: 1140, paddingLeft: 15, paddingRight: 15, color: 'white', fontSize: 12, lineHeight: '13px', textAlign: 'justify', margin: 'auto', marginBottom: 27}}>Ce site présente et compare une sélection de produits et n’effectue pas une comparaison sur la totalité des produits existants. Les informations et/ou documents figurant sur ce site et/ou accessibles par ce site proviennent de sources considérées comme étant fiables. Toutefois, ces informations et/ou documents sont susceptibles de contenir des inexactitudes techniques et des erreurs typographiques. L’utilisation des informations et/ou documents disponibles sur ce site se fait sous l’entière et seule responsabilité de l’utilisateur, qui assume la totalité des conséquences pouvant en découler, sans que Kohdao Ltd. puisse être recherché à ce titre, et sans recours contre ce dernier. Kohdao Ltd. ne pourra en aucun cas être tenu responsable de tout dommage de quelque nature qu’il soit résultant de l’interprétation ou de l’utilisation des informations et/ou documents disponibles sur ce site. Kohdao Ltd est enregistré avec le numéro Data Protection Registration No. ZA891746. Kohdao Ltd confirme que toutes les données personnelles sont enregistrées et stockées en stricte conformité avec la dernière version du Data Protection Act 1998. Vous disposez d’un droit d’accès, de modification, de rectification et de suppression des données qui vous concernent en contactant <Link to={'/contactez-nous/'} style={{color: 'white', textDecoration: 'underline'}}>Kohdao Ltd.</Link></p></Row>

            <Row className="justify-content-center">

              <Col sm="12" className="mt-5 mt-lg-0">
                <Row>
                 
                  <Col xs="6" lg="3">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        Banque
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"} style={{fontWeight: 300}}>
                      <li>
                        <Link to={"/comparateur/"} style={{fontSize: 14}}>
                        <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Comparateur
                          </Link>
                        </li>
                        <li>
                          <Link to={"/banque-en-ligne/"} style={{fontSize: 14}}>
                          <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Banque en ligne
                          </Link>
                        </li>
                        <li>
                        <Link to={"/compte-courant/"} style={{fontSize: 14}}>
                        <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Compte courant
                          </Link>
                        </li>
                        <li>
                        <Link to={"/carte-bancaire/"} style={{fontSize: 14}}>
                        <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Cartes bancaires
                          </Link>
                        </li>
                        <li>
                        <Link to={"/neobanque/"} style={{fontSize: 14}}>
                        <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Néobanques
                          </Link>
                        </li>
                        <li>
                        <Link to={"/banque/"} style={{fontSize: 14}}>
                        <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Avis Banques
                          </Link>
                        </li>
                        <li>
                        <Link to={"/academie/"} style={{fontSize: 14}}>
                        <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Banque Académie
                          </Link>
                        </li>
                       
                    
                    
                    
                      
                      
                      </UlStyled>
                    </div>
                  </Col>

                  <Col xs="6" lg="3">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        Banque Pro
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"} style={{fontWeight: 300}}>
                      <li>
                        <Link to={"/banque-pro/comparateur/"} style={{fontSize: 14}}>
                        <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Comparateur pro
                          </Link>
                        </li>
                      <li>
                        <Link to={"/banque-pro/"} style={{fontSize: 14}}>
                        <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Banque Pro
                          </Link>
                        </li>
                   
                        <li>
                        <Link to={"/banque-pro/auto-entrepreneurs/"} style={{fontSize: 14}}>
                        <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Banque Auto-entrepreneurs
                          </Link>
                        </li>
                        <li>
                        <Link to={"/banque-pro/sas-sasu/"} style={{fontSize: 14}}>
                        <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Banque SAS / SASU
                          </Link>
                        </li>
                        <li>
                        <Link to={"/banque-pro/eurl-sarl/"} style={{fontSize: 14}}>
                        <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Banque EURL / SARL
                          </Link>
                        </li>
                        <li>
                        <Link to={"/banque-pro/restaurant"} style={{fontSize: 14}}>
                        <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Banque Restaurant
                          </Link>
                        </li>
                        <li>
                        <Link to={"/banque-pro/depot-capital/"} style={{fontSize: 14}}>
                        <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Banque Dépôt de Capital
                          </Link>
                        </li>
                      
                      
                      </UlStyled>
                    </div>
                  </Col>


                  <Col xs="6" lg="3">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        Avis Banque
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"} style={{fontWeight: 300}}>
                      <li>
                          <Link to={"/banque/avis-boursorama-banque/"} style={{fontSize: 14}}>
                          <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Boursorama Banque
                          </Link>
                        </li>
                        <li>
                          <Link to={"/banque/avis-hello-bank/"} style={{fontSize: 14}}>
                          <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>HelloBank
                          </Link>
                        </li>
                        <li>
                          <Link to={"/banque/avis-bforbank/"} style={{fontSize: 14}}>
                          <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>BforBank
                          </Link>
                        </li>
                      <li>
                          <Link to={"/banque-pro/avis-manager-one/"} style={{fontSize: 14}}>
                          <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Manager.one
                          </Link>
                        </li>
                        <li>
                          <Link to={"/banque-pro/avis-qonto/"} style={{fontSize: 14}}>
                          <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Qonto
                          </Link>
                        </li>
                        <li>
                          <Link to={"/banque-pro/avis-anytime/"} style={{fontSize: 14}}>
                          <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Anytime
                          </Link>
                        </li>
                        <li>
                          <Link to={"/banque-pro/avis-shine/"} style={{fontSize: 14}}>
                          <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Shine
                          </Link>
                        </li>
                      
                      </UlStyled>
                    </div>
                  </Col>


                 
                  <Col xs="6" lg="3">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        À Propos
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"} style={{fontWeight: 300}}>
                        <li>
                          <Link to={"/qui-sommes-nous/"} style={{fontSize: 14}}>
                          <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Qui sommes-nous?
                          </Link>
                        </li>
                        <li>
                        <Link to={"/contactez-nous/"} style={{fontSize: 14}}>
                        <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Contactez-nous
                          </Link>
                        </li>
                        <li>
                           <Link to={"/plan-du-site/"} style={{fontSize: 14}}>
                           <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Plan du site
                          </Link>
                        </li>
                        <li>
                           <Link to={"/partenaires/"} style={{fontSize: 14}}>
                           <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Partenaires
                          </Link>
                        </li>
                        <li>
                           <Link to={"/mentions-legales/"} style={{fontSize: 14}}>
                           <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Mentions Légales
                          </Link>
                        </li>
                        <li>
                           <Link to={"/politique-de-confidentialite/"} style={{fontSize: 14}}>
                           <FontAwesomeIcon icon={faChevronRight} style={{color: 'white', fontSize: 14, marginRight: 5}}/>Politique de confidentialité
                          </Link>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
               
               
                
                </Row>
              </Col>
            </Row>
          </Box>
          {/* <CookieConsent  style={{ background: "#19191a" }}>This website uses cookies to enhance the user experience.</CookieConsent> */}

<div></div>


          <CookieConsent
  buttonText="J'accepte"
  cookieName="myAwesomeCookieName2"
  style={{ height: 63, marginBottom: 0, background: "white", fontSize: 14, fontWeight: 300, color: '#767676', fontFamily:'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'  }}
  buttonStyle={{ paddingTop: 0, paddingBottom: 0, height: 40, background: "white", fontSize: "13px", color: '#323232', border: '1px solid #767676', borderRadius: 4, fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}
>
  <div style={{marginTop: -10}}>En poursuivant votre navigation sur notre site, vous acceptez l'utilisation de Cookies. <Link to={'/politique-de-confidentialite/'} style={{color: 'rgb(118, 118, 118)'}}>En savoir plus.</Link></div>

</CookieConsent>

          <CopyRightArea dark={isDark ? 1 : 0}>
            <Row className="align-items-center" id='footerMessage'>
         
              
              <Col sm="10">
              <Row>
          <p style={{lineHeight: 1.3, color: 'white', fontSize: 12}}>2021 BankBank &copy; Kohdao Ltd. 7 Clapham Park Terrace, London, SW2 5EA, UK.<br></br>Registration 06261702. Data Protection No: ZA891746.</p>
          </Row>
   
              </Col>
              <Col sm="2" className="text-sm-right text-center" id='footerSocial'>
                <ul className="social-icons">
                  {/* <li>
                    <a href="https://twitter.com/startupmagUK" target="_blank" rel="noreferrer">
                      <i className="icon icon-logo-twitter"></i>
                    </a>
                  </li> */}
                  <li>
                    <a href="https://www.instagram.com/bonjourbankbank/" target="_blank" rel="noreferrer">
                      <i className="icon icon-logo-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/bankbankfrance/" target="_blank" rel="noreferrer">
                      <i className="icon icon-logo-facebook"></i>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </CopyRightArea>
       
        </Container>
      </Box>
    </>
  );
};

export default Footer;
